import React from 'react';

import { Button, Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDateTime } from 'utils';

import { YesNo, TableFooter, Flex } from 'components';
import EmailLogStatisticModal from 'components/EmailLogStatisticModal';
import { PLAIN_TEXT } from 'pages/EmailLog/constants';

const LogsTable = ({
  logs,
  pagination,
  hasAppliedFilters,
  fetchLogCount,
  logsCount,
  loading
}) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="11">
          {(logsCount || !hasAppliedFilters) && pagination}
          {!logsCount && hasAppliedFilters && (
            <Flex spaceBetween>
              <Button primary onClick={fetchLogCount} disabled={loading}>
                {loading ? 'Loading...' : 'Count'}
              </Button>
              {pagination}
            </Flex>
          )}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>Client ID</Table.HeaderCell>
        <Table.HeaderCell>Send date</Table.HeaderCell>
        <Table.HeaderCell>From Name</Table.HeaderCell>
        <Table.HeaderCell>Company</Table.HeaderCell>
        <Table.HeaderCell>Recipient Email</Table.HeaderCell>
        <Table.HeaderCell>Subject</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Opened</Table.HeaderCell>
        <Table.HeaderCell># of Opens</Table.HeaderCell>
        <Table.HeaderCell>Statistic</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {logs.map(log => (
        <Table.Row key={log.id}>
          <Table.Cell width={1}>
            {log.client && (
              <Link
                to={pageUrl(ClientDetail, {
                  clientId: log.client.id
                })}>
                {log.client.id}
              </Link>
            )}
            {!log.client && 'N/A'}
          </Table.Cell>
          <Table.Cell width={2}>{formatDateTime(log.sent_date)}</Table.Cell>
          <Table.Cell width={1}>{log.from_name}</Table.Cell>
          <Table.Cell>{log.client.company || 'N/A'}</Table.Cell>
          <Table.Cell>{log.email}</Table.Cell>
          <Table.Cell width={4}>{log.subject}</Table.Cell>
          <Table.Cell width={1}>{log.email_type}</Table.Cell>
          <Table.Cell textAlign="center">
            {log.email_type === PLAIN_TEXT ? (
              'N/A'
            ) : (
              <YesNo value={log.is_opened} />
            )}
          </Table.Cell>
          <Table.Cell width={1}>
            {log.email_type === PLAIN_TEXT ? 'N/A' : log.statistic_logs_count}
          </Table.Cell>
          <Table.Cell width={1}>
            {log.email_type === PLAIN_TEXT ? (
              'N/A'
            ) : (
              <EmailLogStatisticModal logType={'issue'} log={log} />
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default LogsTable;
